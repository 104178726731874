<template>
  <CustomDialog
    v-model="dialog"
    :title="title | ucwords"
    :button2-text="saveButtonLabel"
    @button1="close"
    @button2="save"
    :main-btn-disabled="!payloadIsValid"
    :max-width="mdAndUp ? 800 : '100%'"
  >
    <template v-slot:content>
      <v-card flat color="transparent">
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col :cols="mdAndUp ? 4 : 12">
                <ServiceMenu
                  left-icon="mdi-room-service"
                  color="secondary"
                  :label="type === 'personal' ? 'Service' : 'Service *'"
                  block
                  :key="hardReload"
                  v-model="mapping.service_id"
                  :list="servicesModule.items"
                  :required="type === 'business'"
                  close-on-content-click
                  height="40"
                  @add-new="emitGlobal('open-new-service-dialog')"
                >
                </ServiceMenu>
              </v-col>
              <v-col :cols="mdAndUp ? 4 : 12">
                <ClientMenu
                  left-icon="mdi-shield-account"
                  :label="type === 'personal' ? 'Client' : `Client*`"
                  color="secondary"
                  block
                  :key="hardReload"
                  :with-create="can_create_client"
                  :required="type === 'business'"
                  :client="mapping.client_id"
                  :company="mapping.client_company_id"
                  @client="mapping.client_id = $event"
                  @company="mapping.client_company_id = $event"
                  :list="clientsModule.items"
                  height="40"
                  @add-new="emitGlobal('open-new-client-dialog')"
                >
                </ClientMenu>
              </v-col>
              <v-col :cols="mdAndUp ? 4 : 12">
                <UserMenu
                  left-icon="mdi-account-hard-hat"
                  :label="
                    type === 'personal'
                      ? 'Project Managers'
                      : `Project Managers **`
                  "
                  color="secondary"
                  block
                  :key="hardReload"
                  v-model="mapping.managers"
                  :multiple="true"
                  :list="adminsAndManagers"
                  height="40"
                  @add-new="emitGlobal('open-new-member-dialog')"
                >
                </UserMenu>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="mdAndUp ? 4 : 12">
                <UserMenu
                  left-icon="mdi-account-multiple-plus"
                  :label="
                    type === 'personal'
                      ? 'Project Members'
                      : `Project Members **`
                  "
                  color="secondary"
                  block
                  :key="hardReload"
                  v-model="mapping.members"
                  :multiple="true"
                  :list="adminsAndMembers"
                  height="40"
                  @add-new="emitGlobal('open-new-member-dialog')"
                >
                </UserMenu>
              </v-col>
              <v-col :cols="mdAndUp ? 4 : 12">
                <DateMenu
                  left-icon="mdi-calendar-arrow-right"
                  color="secondary"
                  label="Start Date *"
                  block
                  :key="hardReload"
                  v-model="mapping.start_date"
                  required
                  height="40"
                  :min="today"
                  :max="mapping.end_date"
                >
                </DateMenu>
              </v-col>
              <v-col :cols="mdAndUp ? 4 : 12">
                <DateMenu
                  left-icon="mdi-calendar-question"
                  color="secondary"
                  label="End Date"
                  block
                  :key="hardReload"
                  v-model="mapping.end_date"
                  height="40"
                  :min="mapping.start_date"
                >
                </DateMenu>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="mdAndUp ? 8 : 12">
                <label class="primary--text subtitle-2">
                  {{ type === 'personal' ? 'Project Title *' : 'Location' }}
                </label>
                <v-text-field
                  v-if="type === 'personal'"
                  dense
                  prepend-inner-icon="mdi-alpha-t-box"
                  :rules="[requiredRule()]"
                  hide-details="auto"
                  raised
                  counter="60"
                  outlined
                  v-model="mapping.title"
                ></v-text-field>
                <v-text-field
                  v-else
                  dense
                  prepend-inner-icon="mdi-map-marker-radius"
                  hide-details="auto"
                  raised
                  counter="60"
                  outlined
                  v-model="mapping.location"
                ></v-text-field>
              </v-col>
              <v-col :cols="mdAndUp ? 4 : 12">
                <cMenu
                  label="Status"
                  block
                  height="50"
                  color="secondary"
                  close-on-content-click
                >
                  <v-list>
                    <v-list-item @click="mapping.status = 'active'">
                      <v-list-item-title>Active</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="mapping.status = 'inactive'">
                      <v-list-item-title>Inactive</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </cMenu>
                <v-chip class="mt-1 fullwidth" label>
                  {{ mapping.status | ucwords }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <label class="primary--text subtitle-2">
                  Project Description
                </label>
                <v-textarea
                  outlined
                  elevation="5"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  auto-grow
                  v-model="mapping.description"
                  rows="4"
                  filled
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
            <label class="caption d-block">* Required </label>
            <label class="caption d-block" v-if="type === 'business'">
              ** Either manager or members should have selected
            </label>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import UserMenu from '@/common/Menus/UserMenu.vue'
import DateMenu from '@/common/Menus/DateMenu.vue'
import ClientMenu from '@/common/Menus/ClientMenu.vue'
import ServiceMenu from '@/common/Menus/ServiceMenu.vue'
import moment from 'moment'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'PersonalProjectDialog',
  components: {
    CustomDialog,
    UserMenu,
    ServiceMenu,
    DateMenu,
    ClientMenu
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object },
    type: { type: String, default: 'business' }
  },
  data: () => ({
    dialog: false,
    valid: false,
    hardReload: 1,
    today: new Date().toISOString().slice(0, 10),
    mapping: {
      type: 'personal',
      service_id: null,
      client_id: null,
      client_company_id: null,
      managers: [],
      members: [],
      title: null,
      location: null,
      description: null,
      start_date: null,
      end_date: null,
      status: 'active'
    },
    defaultState: {
      type: 'personal',
      service_id: null,
      client_id: null,
      client_company_id: null,
      managers: [],
      members: [],
      title: null,
      location: null,
      description: null,
      start_date: null,
      end_date: null,
      status: 'active'
    }
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('projectcreation', [
      'servicesModule',
      'clientsModule',
      'usersModule',
      'groupsModule',
      'managers',
      'members',
      'admins',
      'adminsAndMembers',
      'adminsAndManagers',
      'clients'
    ]),
    can_create_client() {
      return this.$_permissions.can('clients.create')
    },
    isEdit() {
      return this.item ? true : false
    },
    payloadIsValid() {
      return this.type === 'personal'
        ? this.validatePersonal(true)
        : this.validateBusiness(true)
    },
    saveButtonLabel() {
      return this.isEdit ? 'Update' : 'Save'
    },
    title() {
      return this.isEdit
        ? `Update ${this.type} Project`
        : `Create ${this.type} Project`
    },
    client() {
      return this.mapping.client_id
        ? this.get_user(this.mapping.client_id)
        : null
    },
    service() {
      return this.mapping.service_id
        ? this.get_service(this.mapping.service_id)
        : null
    },
    mapping_client: {
      get() {
        return JSON.stringify({
          client: this.mapping.client_id,
          company: this.mapping.client_company_id
        })
      },
      set(val) {
        this.mapping.client_id = val.client || null
        this.mapping.client_company_id = val.company || null
      }
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val && !this.isEdit) {
        this.resetValidation()
        this.clear()
      }
      if (val) this.hardReload++
    },
    item: {
      handler: function (val) {
        this.mapItem(val)
      },
      deep: true,
      immediate: true
    },
    type: {
      handler: function (val) {
        this.mapping.type = val
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('projectcreation', ['get_service', 'get_user', 'get_users']),
    close() {
      this.dialog = false
      this.mapping = _.cloneDeep(this.defaultState)
    },
    handleClient(val) {
      this.mapping.client_id = val.client || null
      this.mapping.client_company_id = val.company || null
    },
    validatePersonal(withError) {
      return this.mapping.title &&
        this.mapping.title.length <= 60 &&
        this.mapping.start_date
        ? true
        : false
    },
    validateBusiness(withError) {
      return this.mapping.client_id &&
        this.mapping.client_company_id &&
        this.mapping.start_date &&
        this.mapping.service_id &&
        (this.mapping.managers.length || this.mapping.members.length)
        ? true
        : false
    },
    save() {
      if (this.payloadIsValid) {
        if (!this.mapping.title && this.mapping.service_id) {
          this.mapping.title = this.service.name
        }
        this.$emit(this.isEdit ? 'update' : 'save', this.mapping)
      } else this.appSnackbar('Please check for required fields', 'error')
    },
    emitGlobal(event) {
      this.$event.$emit(event)
    },
    clear() {
      this.mapping = _.cloneDeep(this.defaultState)
      this.mapping.type = this.type
    },
    mapItem(item) {
      this.mapping = {
        type: item ? item.type : 'personal',
        service_id: item ? item.service_id : null,
        client_id: item ? item.client.id : null,
        location: item && item.props ? item.props.location || null : null,
        client_company_id:
          item && item.client_company_id ? item.client_company_id : null,
        managers: item && item.managers ? item.managers.map((i) => i.id) : [],
        members: item && item.members ? item.members.map((i) => i.id) : [],
        title: item ? item.title : null,
        description: item ? item.description : null,
        start_date: item ? moment(item.started_at).format('YYYY-MM-DD') : null,
        end_date:
          item && item.end_at ? moment(item.end_at).format('YYYY-MM-DD') : null,
        status: item ? item.status : 'active'
      }
    }
  }
}
</script>
<style></style>
