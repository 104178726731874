<template>
  <div>
    <cMenu v-bind="$attrs" close-on-content-click :valid="isValid">
      <v-list dense :max-height="400" style="overflow-y: auto">
        <v-list-item @click.stop="$emit('add-new')" v-if="withCreate">
          <v-list-item-avatar>
            <v-icon color="primary" large>mdi-room-service-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Add new service </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group
          v-model="current"
          :multiple="false"
          :mandatory="required"
          v-if="mappedList.length"
        >
          <template v-for="(item, index) in mappedList">
            <v-list-item
              :key="index"
              :value="item.id"
              active-class="primary light--text"
            >
              <v-list-item-avatar>
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  v-if="item.icon"
                  :src="item.icon"
                ></v-img>
                <v-icon color="accent" large v-else>mdi-image</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name"> </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
        <v-list-item v-else>
          <v-list-item-title>No available selection list</v-list-item-title>
        </v-list-item>
      </v-list>
    </cMenu>

    <template v-if="hasPreview">
      <div class="fullwidth mt-1" v-if="serviceSelected">
        <v-chip large class="fullwidth" label>
          <v-avatar left :size="50" tile>
            <v-img
              contain
              v-if="serviceSelected.icon"
              :src="serviceSelected.icon"
            ></v-img>
            <v-icon color="accent" large v-else>mdi-image</v-icon>
          </v-avatar>
          <div class="ml-2">
            <span class="subtitle-2 mb-0">
              {{ serviceSelected.name | trunc_mid(10, 10) }}
            </span>
          </div>
        </v-chip>
      </div>
      <div class="fullwidth mt-1" v-else>
        <v-chip large class="fullwidth" label>None</v-chip>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'ServiceMenu',
  props: {
    list: { type: Array, required: true },
    value: Number,
    hasPreview: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    withCreate: { type: Boolean, default: true }
  },
  data: () => ({
    current: null
  }),
  watch: {
    value: {
      handler: function (val) {
        this.current = val
      },
      deep: true,
      immediate: true
    },
    current: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isValid() {
      if (!this.required) {
        return true
      } else return this.current ? true : false
    },
    serviceSelected() {
      return _.cloneDeep(this.list).find((i) => i.id === this.current)
    },
    mappedList() {
      return _.cloneDeep(this.list)
    }
  }
}
</script>

<style></style>
