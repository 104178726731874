<template>
  <div>
    <cMenu v-bind="$attrs" :close-on-content-click="!multiple" :valid="isValid">
      <v-list dense :max-height="400" style="overflow-y: auto">
        <v-list-item @click.stop="$emit('add-new')" v-if="withCreate">
          <v-list-item-avatar>
            <v-icon color="primary" large>mdi-account-group</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Add new user </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group
          v-model="current"
          :multiple="multiple"
          :mandatory="mandatory"
          v-if="mappedList.length"
        >
          <template v-for="item in mappedList">
            <v-list-item
              :key="`item-${item.id}`"
              :value="item.id"
              active-class="primary light--text"
            >
              <v-list-item-avatar>
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  :src="item.image_url"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.fullname"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
        <v-list-item v-else>
          <v-list-item-title>No available selection list</v-list-item-title>
        </v-list-item>
      </v-list>
    </cMenu>
    <template v-if="hasPreview">
      <div
        class="fullwidth mt-1"
        v-if="selected && Array.from(selected).length && multiple"
      >
        <Avatars
          :center="false"
          :count="5"
          :items="Array.from(selected)"
        ></Avatars>
      </div>
      <div class="fullwidth mt-1" v-else-if="selected && !multiple">
        <avatar :user="selected"></avatar>
      </div>
      <div class="fullwidth mt-1" v-else>
        <v-chip large class="fullwidth" label>None</v-chip>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'UserMenu',
  props: {
    list: { type: Array, required: true },
    value: [Array, String, Number],
    multiple: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
    hasPreview: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    withCreate: { type: Boolean, default: true }
  },
  data: () => ({
    current: null
  }),
  watch: {
    value: {
      handler: function (val) {
        this.current = !val ? [] : val
      },
      deep: true,
      immediate: true
    },
    current: {
      handler: function (val) {
        this.$emit('input', val)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isValid() {
      if (!this.required) {
        return true
      } else return this.current_ids.length > 0
    },
    selected() {
      if (this.multiple) {
        return _.cloneDeep(this.list).filter((i) =>
          this.current_ids.includes(i.id)
        )
      }
      let index = _.cloneDeep(this.list).findIndex((i) =>
        this.current_ids.includes(i.id)
      )
      return ~index ? _.cloneDeep(this.list)[index] : null
    },
    current_ids() {
      if (!this.current) {
        return []
      }
      if (typeof this.current === 'number') {
        return [this.current]
      }
      return typeof this.current === 'object'
        ? Array.from(this.current)
        : this.current
    },
    mappedList() {
      return _.cloneDeep(this.list).map((item) => {
        item.selected = this.current_ids.includes(item.id)
        return item
      })
    }
  }
}
</script>

<style></style>
