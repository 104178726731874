import { fetch_services } from '@/services/fetch/fetch_services'
//Components
import TableHeader from '@/common/TableHeader.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/ActionDropdown.vue'
import ProjectDialog from '@/modules/Projects/components/ProjectDialog.vue'
import ProjectCardPersonal from '@/modules/Projects/components/ProjectCardPersonal.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'Projects',
    mixins: [fetch_services],
    components: {
        ProjectCardPersonal,
        TableHeader,
        DeleteDialog,
        VueTable,
        Actions,
        ProjectDialog
    },

    data: () => ({
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Project|Personal', disabled: true, route: null }
        ],
        headers: [{
                text: 'Project Title',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Client',
                sortable: false,
                align: 'left',
                width: 90
            },
            {
                text: 'Managers',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Members',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Start Date',
                sortable: false,
                align: 'left'
            },
            {
                text: 'End Date',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Status',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Tasks',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Action',
                value: 'action',
                sortable: false,
                align: 'center',
                width: '40px'
            }
        ],
        search: ''
    }),
    watch: {
        search(val) {
            this.searchProject(val || '')
        }
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    created() {
        this.loadItems(`api/projects?type=personal`, true, null, null)
    },
    computed: {
        ...mapGetters(['view_type', 'user']),
        permisssion() {
            return this.$_permissions.get('projects_own')
        },
        can_add() {
            if (this.user.is_admin) return true
            if (this.user.is_client) return false
            return this.permission && this.permission.create ? true : false
        }
    },
    methods: {
        can_edit(project) {
            if (this.user.is_admin) {
                return true
            }
            let found = project.managers.find((ii) => ii.id === this.user.id)
            return !!found
        },
        can_delete(project) {
            if (this.user.is_admin) {
                return true
            }
            let found = project.managers.find((ii) => ii.id === this.user.id)
            return !!found
        },
        navigate_to_view_project(project) {
            this.$router
                .push({ name: 'hq', params: { id: project.id, type: 'project' } })
                .catch(() => {})
        },
        searchProject(keyword) {
            this.loadItems(
                `api/projects?type=personal&search=${keyword}`,
                true,
                null,
                null
            )
        },
        handleSaveProject(payload) {
            this.addItem(
                'api/projects',
                payload,
                'Personal project created!',
                () => {
                    this.action_add_edit_dialog(false, null)
                },
                null
            )
        },
        handleUpdateProject(payload) {
            this.updateItem(
                `api/projects/${this.activeItem.id}`,
                payload,
                'Personal project updated!',
                () => {
                    this.action_add_edit_dialog(false, null)
                    this.activeItem = null
                },
                null
            )
        },
        handleDeleteProject() {
            this.deleteItem(
                `api/projects/${this.activeItem.id}`,
                'Personal Project deleted',
                () => {
                    this.activeItem = null
                    this.confirm_dialog = false
                },
                null
            )
        }
    }
}