//import { list_functionality } from '@/services/list-functionality/list-functionality'
import { fetch_services } from '@/services/fetch/fetch_services'

//Components
import Breadcrumb from '@/common/Breadcrumb.vue'
import TableHeader from '@/common/TableHeader.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import ClientsDialog from '@/modules/Clients/components/ClientsDialog/ClientsDialog.vue'
import TeamsDialog from '@/modules/Teams/components/TeamsDialog/TeamsDialog.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/ActionDropdown.vue'
import GroupsDialog from '@/modules/Settings-Groups/components/GroupsDialog/GroupsDialog.vue'
import { mapGetters } from 'vuex'
import ProjectDialog from '@/modules/Projects/components/ProjectDialog.vue'
import ProjectCardBusiness from '@/modules/Projects/components/ProjectCardBusiness.vue'
export default {
    name: 'Projects',
    mixins: [fetch_services],
    components: {
        Breadcrumb,
        TableHeader,
        GroupsDialog,
        DeleteDialog,
        ClientsDialog,
        TeamsDialog,
        VueTable,
        Actions,
        ProjectCardBusiness,
        ProjectDialog
    },

    data: () => ({
        add_new_service_dialog: false,
        add_new_client_dialog: false,
        add_new_member_dialog: false,
        bulk_delete_dialog: false,
        add_group_dialog: false,
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Project|Business', disabled: true, route: null }
        ],
        headers: [{
                text: 'Service',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Client',
                sortable: false,
                align: 'left',
                width: 90
            },
            {
                text: 'Business',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Managers',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Team',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Start Date',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Location',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Status',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Tasks',
                sortable: false,
                align: 'left'
            },
            {
                text: 'Action',
                value: 'action',
                sortable: false,
                align: 'center',
                width: '40px'
            }
        ],

        table_config: {
            add_message: 'New project added successfully!',
            update_message: 'Project updated successfully!',
            delete_message: 'Project deleted successfully!',
            refresh_table_message: 'Table refreshed',
            refresh_table_api_name: 'paginate_clients_table'
        },
        search: '',
        activeService: null,
        serviceList: []
    }),
    watch: {
        search(val) {
            this.searchProject(val || '')
        }
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    created() {
        this.load_projects()
    },
    computed: {
        ...mapGetters(['view_type', 'user']),
        ...mapGetters('projectcreation', ['servicesModule']),
        can_add() {
            if (this.user.is_admin) return true
            if (this.user.is_client) return false
            return this.$_permissions.can('projects.create')
        }
    },
    methods: {
        can_edit(project) {
            if (this.user.is_admin) {
                return true
            }
            let found = project.managers.find((ii) => ii.id === this.user.id)
            return !!found || this.$_permissions.can('projects.update')
        },
        can_delete(project) {
            if (this.user.is_admin) {
                return true
            }
            let found = project.managers.find((ii) => ii.id === this.user.id)
            return !!found || this.$_permissions.can('projects.delete')
        },
        filterByService(service) {
            this.activeService = service === 'all' ? null : service
            this.loadItems(
                `api/projects?type=business&service=${
          service === 'all' ? 'all' : service.id
        }`,
                true,
                null,
                null
            )
        },
        searchProject(keyword) {
            this.loadItems(
                `api/projects?type=business&search=${keyword}`,
                true,
                null,
                null
            )
        },
        load_more() {
            this.loadMoreItems()
        },
        load_projects() {
            this.loadItems(`api/projects?type=business`, true, null, null)
        },
        navigate_to_view_project(id) {
            this.$router.push({
                name: 'hq',
                params: { id: id, type: 'project' }
            })
        },
        handleSaveProject(payload) {
            this.addItem(
                'api/projects',
                payload,
                'Business project created!',
                () => {
                    this.action_add_edit_dialog(false, null)
                },
                null
            )
        },
        handleUpdateProject(payload) {
            this.updateItem(
                `api/projects/${this.activeItem.id}`,
                payload,
                'Business project updated!',
                () => {
                    this.action_add_edit_dialog(false, null)
                    this.activeItem = null
                },
                null
            )
        },
        handleDeleteProject() {
            this.deleteItem(
                `api/projects/${this.activeItem.id}`,
                'Business Project deleted',
                () => {
                    this.activeItem = null
                    this.confirm_dialog = false
                },
                null
            )
        }
    }
}