<template>
  <div class="project-campaign">
    <ClientsDialog
      v-model="clientsModule_add_dialog"
      dialog-title="Add New Client"
      @save="handleSaveClients"
    ></ClientsDialog>

    <TeamsDialog
      title="Add New Member"
      v-model="usersModule_add_dialog"
      :roles="groupsModule.items"
      @save="handleSaveMembers"
      @add-new-group="
        set_module_dialog({ module: 'groupsModule', value: true })
      "
    ></TeamsDialog>

    <ServiceMultipleAddDialog
      v-model="servicesModule_add_dialog"
      @save="handleSaveServices"
    ></ServiceMultipleAddDialog>

    <GroupsDialog
      title="Add New Group"
      :roles="groupsModule.items"
      v-model="groupsModule_add_dialog"
      @save="handleSaveGroups"
    ></GroupsDialog>

    <v-tabs
      class="tabs rounded-10 mb-2 border-1 border-color--grey"
      centered
      grow
      height="48px"
      hide-slider
      v-model="active_tab"
      :show-arrows="mdAndDown"
    >
      <v-tab
        class="text-none"
        active-class="rounded-tl-10 light--text primary rounded-bl-10"
        exact
        :value="0"
        @click="handleChangeTab(tabs[0], 0)"
      >
        {{ tabs[0].name | ucwords }}
      </v-tab>
      <v-tab
        class="text-none"
        active-class="rounded-tr-10 light--text primary rounded-br-10"
        exact
        :value="1"
        @click="handleChangeTab(tabs[1], 1)"
      >
        {{ tabs[1].name | ucwords }}
      </v-tab>
    </v-tabs>
    <BusinessProject v-if="active_tab === 0"></BusinessProject>
    <PersonalProject v-else-if="active_tab === 1"></PersonalProject>
  </div>
</template>

<script>
import PersonalProject from '@/modules/Project-Personal/Projects.vue'
import BusinessProject from '@/modules/Project-Business/Projects.vue'
import ClientsDialog from '@/modules/Clients/components/ClientsDialog/ClientsDialog.vue'
import TeamsDialog from '@/modules/Teams/components/TeamsDialog/TeamsDialog.vue'
import GroupsDialog from '@/modules/Settings-Groups/components/GroupsDialog/GroupsDialog.vue'
import ServiceMultipleAddDialog from '@/modules/Services-List/components/ServiceMultipleAddDialog.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Projects',
  components: {
    BusinessProject,
    PersonalProject,
    ClientsDialog,
    TeamsDialog,
    GroupsDialog,
    ServiceMultipleAddDialog
  },
  data: () => ({
    active_tab: 'business',
    text_tab: 'business',
    tabs: [
      { name: 'Business Projects', id: 'business' },
      { name: 'Personal Projects', id: 'personal' }
    ]
  }),
  computed: {
    ...mapGetters('projectcreation', [
      'servicesModule',
      'clientsModule',
      'usersModule',
      'groupsModule'
    ]),
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        { text: 'Projects', disabled: true, router_name: null },
        { text: this.text_tab, disabled: true, router_name: null }
      ]
    },
    clientsModule_add_dialog: {
      get() {
        return this.clientsModule.add_dialog
      },
      set(val) {
        this.set_module_dialog({ module: 'clientsModule', value: val })
      }
    },
    usersModule_add_dialog: {
      get() {
        return this.usersModule.add_dialog
      },
      set(val) {
        this.set_module_dialog({ module: 'usersModule', value: val })
      }
    },
    servicesModule_add_dialog: {
      get() {
        return this.servicesModule.add_dialog
      },
      set(val) {
        this.set_module_dialog({ module: 'servicesModule', value: val })
      }
    },
    groupsModule_add_dialog: {
      get() {
        return this.groupsModule.add_dialog
      },
      set(val) {
        this.set_module_dialog({ module: 'groupsModule', value: val })
      }
    }
  },
  watch: {
    '$route.params': {
      handler(val) {
        this.active_tab = 'business'
        this.text_tab = val.type
        if (
          val.hasOwnProperty('type') &&
          ['business', 'personal'].includes(val.type)
        ) {
          this.active_tab = this.tabs.findIndex((i) => i.id === val.type)
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  created() {
    this.fetchModule('groupsModule')
    this.fetchModule('servicesModule')
    this.fetchModule('usersModule')
    this.fetchModule('clientsModule')

    this.$event.$on('open-new-service-dialog', () => {
      this.set_module_dialog({ module: 'servicesModule', value: true })
    })
    this.$event.$on('open-new-client-dialog', () => {
      this.set_module_dialog({ module: 'clientsModule', value: true })
    })
    this.$event.$on('open-new-member-dialog', () => {
      this.set_module_dialog({ module: 'usersModule', value: true })
    })
    this.$event.$on('open-new-group-dialog', () => {
      this.set_module_dialog({ module: 'groupsModule', value: true })
    })
  },
  methods: {
    ...mapActions('projectcreation', [
      'fetchModule',
      'createModule',
      'createModules'
    ]),
    ...mapMutations('projectcreation', ['set_module_dialog']),
    handleChangeTab(tab, index) {
      this.active_tab = index
      this.$router
        .push({
          name: 'projects',
          params: {
            type: tab.id
          }
        })
        .catch(() => {})
    },
    handleSaveServices(payload) {
      this.createModules({
        module: 'servicesModule',
        payload: { names: payload.map((i) => i.name) },
        cb: null
      })
    },
    handleSaveMembers(payload) {
      this.createModule({ module: 'usersModule', payload: payload, cb: null })
    },
    handleSaveGroups(payload) {
      this.createModule({ module: 'groupsModule', payload: payload, cb: null })
    },
    handleSaveClients(payload) {
      this.createModule({
        module: 'clientsModule',
        payload: payload,
        cb: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.active-tab {
  background: #223a6b;
  color: #ffffff;
}
</style>
