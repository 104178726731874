<template>
  <div>
    <cMenu v-bind="$attrs" close-on-content-click :valid="isValid">
      <v-list dense :max-height="400" style="overflow-y: auto">
        <v-list-item @click.stop="$emit('add-new')" v-if="withCreate">
          <v-list-item-avatar>
            <v-icon color="primary" large>mdi-shield-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Add new client
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar tile>
            <v-icon color="primary" large>mdi-plus-circle-outline</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item-group
          v-model="current"
          :multiple="false"
          :mandatory="required"
          v-if="mappedList.length"
        >
          <template v-for="(item, index) in mappedList">
            <v-list-item
              :key="index"
              :value="item.value"
              active-class="primary light--text"
            >
              <v-list-item-avatar>
                <v-img
                  contain
                  v-if="item.client.image_url"
                  :src="item.client.image_url"
                ></v-img>
                <v-icon large v-else>mdi-shield-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.company.name">
                </v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.client.fullname"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar tile>
                <v-img
                  contain
                  v-if="item.company.company_logo"
                  :src="item.company.company_logo"
                ></v-img>
                <v-icon color="primary" large v-else>
                  mdi-office-building
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </template>
        </v-list-item-group>
        <v-list-item v-else>
          <v-list-item-title>No available selection list</v-list-item-title>
        </v-list-item>
      </v-list>
    </cMenu>

    <template v-if="hasPreview">
      <div class="fullwidth mt-1" v-if="clientSelected && companySelected">
        <v-chip large class="fullwidth" label>
          <v-avatar left :size="50" tile>
            <v-img
              contain
              v-if="companySelected.company_logo"
              :src="companySelected.company_logo"
            ></v-img>
            <v-icon large v-else>mdi-office-building</v-icon>
          </v-avatar>
          <div class="ml-2">
            <span class="subtitle-2 mb-0"> {{ companySelected.name }} </span>
            <br />
            <span class="caption mt-0"> {{ clientSelected.fullname }} </span>
          </div>
        </v-chip>
      </div>
      <div class="fullwidth mt-1" v-else>
        <v-chip large class="fullwidth" label>None</v-chip>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  inheritAttrs: false,
  name: 'ClientMenu',
  props: {
    list: { type: Array, required: true },
    client: Number,
    company: Number,
    hasPreview: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    withCreate: { type: Boolean, default: true }
  },
  data: () => ({
    current: { client: null, company: null }
  }),
  watch: {
    company: {
      handler: function (val) {
        this.current.company = val
      },
      deep: true,
      immediate: true
    },
    client: {
      handler: function (val) {
        this.current.client = val
      },
      deep: true,
      immediate: true
    },
    current: {
      handler: function (val) {
        this.$emit('client', val ? val.client : null)
        this.$emit('company', val ? val.company : null)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isValid() {
      if (!this.required) {
        return true
      } else return this.current.client && this.current.company ? true : false
    },
    clientSelected() {
      return _.cloneDeep(this.list).find((i) => i.id === this.current.client)
    },
    companySelected() {
      if (!this.clientSelected) {
        return null
      }
      return Array.from(this.clientSelected.client_companies).find(
        (i) => i.id === this.current.company
      )
    },
    mappedList() {
      let clients = _.cloneDeep(this.list)
      let companies = []
      clients.forEach((client) => {
        let cCompanies = client.client_companies.map((company) => {
          return {
            value: { client: client.id, company: company.id },
            client: client,
            company: company
          }
        })
        companies.push(...cCompanies)
      })
      return companies
    }
  }
}
</script>

<style></style>
